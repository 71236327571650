/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./withdraw.vue?vue&type=template&id=2a4ae530&scoped=true"
import script from "./withdraw.vue?vue&type=script&lang=js"
export * from "./withdraw.vue?vue&type=script&lang=js"
import style0 from "./withdraw.vue?vue&type=style&index=0&id=2a4ae530&prod&lang=scss"
import style1 from "./withdraw.vue?vue&type=style&index=1&id=2a4ae530&prod&lang=scss&scoped=true"
import style2 from "./withdraw.vue?vue&type=style&index=2&id=2a4ae530&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a4ae530",
  null
  
)

export default component.exports