import Vue from "vue";
import vailcode from "@utils/errcode302";
import cookie from "js-cookie";
import querystring from "querystring";

export default {
  namespaced: true,
  state: {
    taskInfo:null,
    itemList: [],
    rankingList:[],
    rankingTotal:0,
    logList:[],
    logHasMore : false,
    logLoading : false,
    logCondition:{
      page:1,
      limit:20
    },
    showPop:false,
    code:'',
    startTime:0,
    historyCount:0
  },
  mutations: {
    setTaskInfo:(state, payload) => {
      state.taskInfo = payload;
    },
    setItemList: (state, payload) => {
      state.itemList = payload;
    },
    setRankingList: (state, payload) => {
      state.rankingList = payload;
    },
    setRankingTotal:(state, payload) => {
      state.rankingTotal = payload;
    },
    setLogList: (state, payload) => {
      state.logList = payload;
    },
    setLogCondition:(state, payload) => {
      state.logCondition = payload
    },
    setLogLoading:(state, payload) => {
        state.logLoading = payload
    },
    setLogHasMore:(state, payload) => {
        state.logHasMore = payload
    },
    appendLogList:(state, payload) =>{
        state.logList = state.logList.concat(payload)
    },
    setShowPop: (state, payload) => {
      state.showPop = payload;
    },
    setCode: (state, payload) => {
      state.code = payload;
    },
    setStartTime:(state, payload) =>{
      state.startTime = payload;
    },
    setHistoryCount(state, payload){
      state.historyCount = payload
    }
  },
  actions: {
    getTaskInfo:async ({ state, commit }) => {
      const rs = await Vue.prototype.$http.post("/face/task/info",{});
      vailcode(
        rs,
        () => {
          commit("setTaskInfo", rs.data);
        }
      );
    },
    getItemList:async ({ state, commit },[callback,failcallback]) => {
        const rs = await Vue.prototype.$http.post("/face/items",{});
        vailcode(
          rs,
          () => {
            commit("setItemList", rs.data);
            typeof callback=='function' && callback(rs.data)
          },
          ()=>{
            typeof failcallback=='function' && failcallback(rs)
          }
        );
    },
    getRankingList:async ({ state, commit },limit = 10) => {
      const rs = await Vue.prototype.$http.post("/face/ranking",{limit});
      vailcode(rs, () => {
        commit("setRankingList", rs.data.rows);
        commit("setRankingTotal", rs.data.total);
      });
    },
    getLogList:async ({ state, commit },[callback,failcallback]) => {
      if (state.logLoading) return;
      commit('logLoading', true);
      const rs = await Vue.prototype.$http.post("/face/logs",state.logCondition);
      vailcode(rs, () => {
          if(rs.data.currentPage == 1){
            commit("setLogList", rs.data.items);
          }
          else{
            commit('appendLogList', rs.data.items);
          }
          if(rs.data.lastPage > rs.data.currentPage ){
            commit('setLogHasMore', true);
          }
          else{
            commit('setLogHasMore', false);
          }
          commit('setLogLoading', false);
          typeof callback=='function' && callback(rs.data)
      },
      ()=>{
        typeof failcallback=='function' && failcallback(rs)
      });
    },
    faceClaim:async ({ state, commit },[index,callback,failcallback]) => {
      const rs = await Vue.prototype.$http.post("face/claim",{index});
      vailcode(
        rs,
        () => {
          typeof callback=='function' && callback(rs.data)
        },
        ()=>{
          typeof failcallback=='function' && failcallback(rs)
        }
      );
    },
    faceCheck:async ({ state, commit },[requestId,callback,failcallback]) => {
      const rs = await Vue.prototype.$http.post("face/check",{requestId});
      vailcode(
        rs,
        () => {
          let taskInfo = state.taskInfo
          taskInfo.completed_count ++
          commit('setTaskInfo', taskInfo);
          typeof callback=='function' && callback(rs.data)
        },
        ()=>{
          typeof failcallback=='function' && failcallback(rs)
        }
      );
    },
  },
  getters: {},
};
