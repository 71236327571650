import Vue from "vue";
import vailcode from "@utils/errcode";
import cookie from "js-cookie";
import querystring from "querystring";

export default {
  namespaced: true,
  state: {
    depositLog:[],
    logHasMore : false,
    logLoading : false,
    logCondition:{
      chain:"",
      symbol:"",
      page:1,
      limit:20
    },
  },
  mutations: {
    setDepositLog: (state, payload) => {
        state.depositLog = payload;
    },
    setLogCondition:(state, payload) => {
      state.logCondition = payload
    },
    setLogLoading:(state, payload) => {
        state.logLoading = payload
    },
    setLogHasMore:(state, payload) => {
        state.logHasMore = payload
    },
    appendDepositLog:(state, payload) =>{
        state.depositLog = state.depositLog.concat(payload)
    },
    updateLogItem:(state, payload)=>{
        state.depositLog.forEach((item,index) => {
          if(item.id == payload.id){
            Vue.prototype.$set(state.depositLog,index,payload);
          }
        }); 
    }
  },
  actions: {
    getDepositLog:async ({ state, commit },[callback,failcallback]) => {
      if (state.logLoading) return;
      commit('setLogLoading', true);
      const rs = await Vue.prototype.$http.post("/user/assets/deposit/logs",state.logCondition);
      vailcode(rs, () => {
          if(rs.data.currentPage == 1){
            commit("setDepositLog", rs.data.items);
          }
          else{
            commit('appendDepositLog', rs.data.items);
          }
          if(rs.data.lastPage > rs.data.currentPage ){
            commit('setLogHasMore', true);
          }
          else{
            commit('setLogHasMore', false);
          }
          commit('setLogLoading', false);
          typeof callback=='function' && callback(rs.data)
      },
      ()=>{
        typeof failcallback=='function' && failcallback(rs)
      });
    },
    doDeposit:async({ state, commit },[chain,symbol,amount,address,callback,failcallback]) => {
      const rs = await Vue.prototype.$http.post("/user/assets/deposit",{chain,symbol,amount,address});
      vailcode(rs, () => {
        typeof callback=='function' && callback(rs.data)
      },
      ()=>{
        typeof failcallback=='function' && failcallback(rs)
      });
    },
    getDepositLogStatus:async ({ state, commit },[orderId,action,callback,failcallback]) => {
      const rs = await Vue.prototype.$http.post("/user/assets/order/check",{orderId:orderId,action:action});
      vailcode(rs, () => {
          commit("updateLogItem", rs.data);
          typeof callback=='function' && callback(rs.data)
      },
      ()=>{
        typeof failcallback=='function' && failcallback(rs)
      });
    }
  },
  getters: {},
};
